import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-spinner-overlay',
	templateUrl: './spinner-overlay.component.html',
	styleUrls: ['./spinner-overlay.component.scss'],
})
export class SpinnerOverlayComponent implements OnInit {
	isDestroyed: boolean;

	constructor() {
		this.isDestroyed = false;
	}

	ngOnInit(): void {}
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	animations: [
		trigger('animateDestroy', [
			state('enter', style({ opacity: '1' })),
			state('void', style({ opacity: '0' })),
			state('leave', style({ opacity: '0' })),
			transition('* => *', animate('500ms ease')),
		]),
	],
	encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {
	animationState: 'void' | 'enter' | 'leave' = 'void';

	constructor() {}

	ngOnInit(): void {
		this.animationState = 'enter';
	}

	ngOnDestroy(): void {
		this.animationState = 'leave';
	}
}

import { IAppState } from '../../data/general.data';
import { createSelector } from '@ngrx/store';
import { IEntryHash, IEntryHashState, IEntryHashMap } from './private.model';

export const selectEntryHashState = (state: IAppState) => state.entryHash;

export const selectEntryHashes = createSelector(selectEntryHashState, (state: IEntryHashState) => state.entryHashes);

export const selectPublicEntryHashes = createSelector(selectEntryHashState, (state: IEntryHashState) =>
	state.entryHashes.filter((entry: IEntryHash) => entry.public)
);

export const selectEntryHashesMap = createSelector(selectEntryHashes, (entryHashes: IEntryHash[]) =>
	entryHashes.reduce((map: IEntryHashMap, entryHash: IEntryHash) => {
		map[entryHash.hash] = {
			redirect: entryHash.redirect,
			public: entryHash.public,
		};
		return map as IEntryHashMap;
	}, {} as IEntryHashMap)
);
